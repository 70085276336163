import { fetchAndParseWithRetry } from './networkUtils';
import { isError } from './typeUtils';

type ClearbitResponse = {
  domain: string;
  geo: {
    country: string;
  };
  // Company name
  name: string;
};

type ClearbitUser = {
  country: string;
  domain: string;
  name: string;
};

export const fetchUserData = async ({
  email,
  hostname = '',
}: {
  email: string;
  // Typically, no need to set since this is only used within www
  hostname?: string;
}): Promise<ClearbitUser | null> => {
  try {
    const url = `${hostname}/api/www-vercel-user-enrichment`;

    const data = await fetchAndParseWithRetry<{
      user: ClearbitResponse | null;
    }>(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    // console.log('data: ', data); // debug
    const { user } = data || {};

    if (!user) {
      return null;
    }

    return {
      domain: user.domain,
      country: user.geo.country,
      name: user.name,
    };
  } catch (err) {
    if (isError(err)) {
      console.error(
        `Something went wrong fetching Clearbit company: ${err.toString()}`
      );
    }
  }

  return null;
};
