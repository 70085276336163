import { isNonEmptyArray } from './validationUtils';

type GqlResponse<T> = {
  data: T;
  errors: Error[];
};

export const postRequestToJSONAPI = async ({
  url,
  headers = {},
  body,
}: {
  url: string;
  headers?: Record<string, unknown>;
  body: string;
}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body,
  });

  return await response.json();
};

export const attemptWithRetries = async <T>(
  fn: () => Promise<T | null>,
  retryLimit: number = 5,
  retriesLeft: number = retryLimit
): Promise<T | null> => {
  retriesLeft -= 1;

  try {
    return await fn();
  } catch (err) {
    if (retriesLeft > 0) {
      return await attemptWithRetries(fn, retryLimit, retriesLeft);
    }

    console.error(
      `Failed to run attempted function after ${retryLimit} attempts.\n`,
      err
    );
    throw err;
  }
};

export const fetchAndParseWithRetry = async <T>(
  url: string,
  options?: RequestInit,
  retries?: number
): Promise<T> => {
  const handler = async () => {
    // forcing it to be true here
    const response = await fetch(url, options);
    return await response.json();
  };

  const attemptedResult = await attemptWithRetries<T>(handler, retries);

  if (!attemptedResult) {
    throw new Error(
      `Failed to fetch and parse. Error output should be above this message. ${JSON.stringify(
        { options },
        undefined,
        4
      )}`
    );
  }

  return attemptedResult;
};

export const attemptGqlQuery = async <T>({
  url,
  headers,
  query,
  variables,
}: {
  headers?: Record<string, string>;
  url: string;
  query: string;
  variables?: Record<string, unknown>;
}) => {
  const body = JSON.stringify({
    variables,
    query,
  });

  const result = await fetchAndParseWithRetry<GqlResponse<T>>(url, {
    method: 'POST',
    headers,
    body,
  });

  if (isNonEmptyArray(result.errors)) {
    throw new Error(
      `Encountered graphql request errors: \n${JSON.stringify(
        result.errors,
        undefined,
        4
      )}`
    );
  }

  return result?.data || null;
};
