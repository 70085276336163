import { postRequestToJSONAPI } from '@rippling/utils/networkUtils';
import {
  isNonEmptyArray,
  isNonEmptyObject,
} from '@rippling/utils/validationUtils';
import {
  OPENPRISE_API_KEY,
  OPENPRISE_ENDPOINT,
  OPENPRISE_POD_NAME,
} from ':constants/env';
import {
  OpenpriseError,
  OpenpriseErrorCodes,
  OpenpriseErrorMessages,
  OpenpriseErrorSeverity,
} from './errors/openprise-error';
import { VercelLogMessage, log } from ':lib/vercel';

export const CORRELATION_ID_HEADER = 'x-rippling-cid';
export const WWW_SERVICE_NAMESPACE = 'rippling-www';

export type FieldValidation = {
  fieldName: string;
  expected: string;
};

const isOpenpriseErrorResponse = (json: Record<string, any>): boolean => {
  return Boolean(
    !json.success ||
      json.error_code ||
      json.error_message ||
      json.incorrect_attributes
  );
};

/*
Fetch handlers
*/

// Function for vercel server to push form submission to Openprise. On success, it
// will return the fields that were accepted by Openprise. This can be used to
// determine which fields weren't accepted, which can be reported.
export const postToOpenprise = async (
  formData: Record<string, any>,
  correlationId: string
) => {
  try {
    const headers = {
      apiKey: OPENPRISE_API_KEY,
      podName: OPENPRISE_POD_NAME,
      [CORRELATION_ID_HEADER]: correlationId,
    };

    const response = await postRequestToJSONAPI({
      url: OPENPRISE_ENDPOINT,
      headers,
      body: JSON.stringify([formData]),
    });

    // catch any hard errors
    if (isOpenpriseErrorResponse(response)) {
      const {
        data,
        error_code: errorCode,
        error_message: errorMessage,
        incorrect_attributes: incorrectAttributes = {},
      } = response;

      throw new OpenpriseError({
        correlationId,
        statusCode: 400,
        severity: OpenpriseErrorSeverity.Error,
        message: OpenpriseErrorMessages.OpenpriseServiceRequestConfigError,
        errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_REQUEST_CONFIG_ERROR,
        contextualData: {
          errorCode,
          errorMessage,
          incorrectAttributes, // OP validation errors
          acceptedFields: Object.keys(data?.[0] || {}), // sometimes we'll get empty data ([])
        },
      });
    }

    const acceptedFormData = response.data?.[0] || null;

    // catch any soft config errors
    if (!isNonEmptyObject(acceptedFormData)) {
      // [possible failure] if returned form data is empty for some reason... (its happened before...)
      const warningError = new OpenpriseError({
        correlationId,
        statusCode: 200,
        severity: OpenpriseErrorSeverity.Warning,
        message: OpenpriseErrorMessages.OpenpriseServiceEmptyResponse,
        errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_SERVICE_CONFIG_ERROR,
        contextualData: { responseData: response.data },
      });

      logOpenpriseErrorMessage(warningError);
    } else {
      // [possible success] We compare the returned form data vs submitted form data
      const unconfiguredFields = getUnconfiguredFields(
        formData,
        acceptedFormData
      );

      if (isNonEmptyArray(unconfiguredFields)) {
        const error = new OpenpriseError({
          correlationId,
          statusCode: 200,
          contextualData: { unconfiguredFields: unconfiguredFields },
          severity: OpenpriseErrorSeverity.Warning,
          message: OpenpriseErrorMessages.OpenpriseServiceUnconfiguredFields,
          errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_SERVICE_CONFIG_ERROR,
        });

        logOpenpriseErrorMessage(error);
      }
    }

    logOpenpriseMessage({
      message: `Form submitted - ${correlationId}`,
      type: 'info',
    });

    return acceptedFormData;
  } catch (error) {
    const errorToUse =
      error instanceof OpenpriseError
        ? error
        : new OpenpriseError({
            correlationId,
            statusCode: 500,
            message: error.message,
            severity: OpenpriseErrorSeverity.Error,
            errorCode: OpenpriseErrorCodes.OPENPRISE_SERVICE_UNKNOWN_ERROR,
          });

    logOpenpriseErrorMessage(errorToUse);
  }
};

// Function used in the form submission handler on the client to the vercel server endpoint,
// which is used to proxy the request to Openprise
export const postToOpenpriseProxy = async (formData: Record<string, any>) => {
  return fetch('/api/www-vercel-openprise', {
    method: 'post',
    body: JSON.stringify(formData),
    headers: { 'Content-Type': 'application/json' },
  });
};

/*
Post-form submission
*/

// after submission, get the fields that Openprise didn't accept
export const getUnconfiguredFields = (
  submittedFormData: Record<string, any>,
  acceptedFormData: Record<string, any>
): string[] => {
  const fieldsSubmittedNames = Object.keys(submittedFormData);
  const fieldsAcceptedNames = Object.keys(acceptedFormData);

  return fieldsSubmittedNames.filter(
    (value) => !fieldsAcceptedNames.includes(value)
  );
};

/**
 * Logging
 */
export const logOpenpriseErrorMessage = (error: OpenpriseError) => {
  const { contextualData, correlationId, errorCode, message, severity } = error;

  logOpenpriseMessage({
    data: {
      contextualData,
      correlationId,
      errorCode,
    },
    message,
    type: severity === OpenpriseErrorSeverity.Error ? 'error' : 'warn',
  });
};

export const logOpenpriseMessage = (
  message: Omit<VercelLogMessage, 'apiName'>
) => {
  log({
    apiName: 'www-vercel-openprise',
    ...message,
  });
};

// Remove this mapping and "getFormDataWithCampaignName" once Marketo and p_name is deprecated
export const pNameToCampaignNameMap = {
  'request-demo': 'WF-Web Form-Rippling Request Demo',
  'request-quote': 'WF-Web Form-Rippling Request Quote',
  'video-tour': 'WF-Web Form-Product Tour',
  'lp-home': 'WF-Web Form-Rippling Request Demo',
  peopleops: 'FB-Facebook Instapage',
  'content-lp': 'WF-Web Form-Content Webinars LP',
  peo: 'WF-Web Form-PEO Demo Request',
  'contact-us': 'WF-Web Form-Contact Us',
  subscribe: 'Product Update Subscribe',
  'product-tour': 'WF-Web Form-Product Tour',
  'shell-program': 'WF-xxDemo Request Shell Program',
  'ur-program': 'Beta and Research Subscribe',
  'quiz-program': 'WF-PEO Quiz Trigger Program',
  'WF-xxDemo Request Qualifying Data': 'WF-xxDemo Request Qualifying Data',
  'Partner Event 2022-06-08 AICPA Happy Hour':
    'Partner Event 2022-06-08 AICPA Happy Hour',
  'simple-form': 'WF-Web Form-Rippling Demo Drop Off',
  '2023-06-10 SHRM Conference': '2023-06-10 SHRM Conference',
} as const;

export const getCampaignNameFromPName = (
  formData: Record<string, any>
): string => {
  if (formData.video_tour) {
    return pNameToCampaignNameMap['video-tour'];
  }

  if (formData.request_quote) {
    return pNameToCampaignNameMap['request-quote'];
  }

  if (formData.p_name) {
    return (
      pNameToCampaignNameMap[formData.p_name] ||
      pNameToCampaignNameMap['request-demo']
    );
  }

  return pNameToCampaignNameMap['request-demo'];
};

export const getFormDataWithCampaignName = (
  formData: Record<string, any>
): Record<string, any> => {
  if (formData.campaign_name) {
    return formData;
  }

  const resultFormData = { ...formData };
  resultFormData.campaign_name = getCampaignNameFromPName(resultFormData);

  return resultFormData;
};
